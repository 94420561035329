<template>
  <ul class="menu-nav">
    <router-link
      v-for="(menuItem, index) in menuItems"
      v-bind:key="index"
      :to="'/' + menuItem[1]"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text"> {{ menuItem[0] }}</span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
export default {
  props: ["menuItems"],
  name: "KTMenu",
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  }
};
</script>
